import { useState } from 'react';
import {Modal, Form, Input, Button, message} from 'antd';
import API from '../../axiosApi';
import TextArea from "antd/lib/input/TextArea";

const CancelSubscriptionModal = ({ open, setOpen, id, setId, type, setType }) => {
  const [ form ] = Form.useForm();
  const [ loading, setLoading ] = useState( false );

  const handleCancel = () => {
    form.resetFields();
    setLoading( false );
    setOpen( false );
    setId( null );
    setType( null );
  }

  const onFinish = (values) => {
    setLoading( true );

    API.post(`transaction/subscription/cancel`, {
      id,
      type,
      ...values
    })
      .then((response) => {
        const data = response.data.response;

        handleCancel();

        window.location.reload();
      }, (error) => {
        const data = error.response.data;

        message.error(data.error);

        setLoading( false );
      })
  };

  return (
    <Modal
        className={'cancel-subscription-modal'}
        title="Are you want to cancel your subscription?"
        open={ open }
        onCancel={ handleCancel }
        okText="Confirm"
        footer="">
        <Form
          form={ form }
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 19,
          }}
          style={{
            maxWidth: 1000,
          }}
          autoComplete="off"
          onFinish={ onFinish }
        >
          <Form.Item
            label="Reason"
            name="reason"
            rules={[
              {
                type: 'string',
                required: true,
                min: 1,
                max: 128,
                message: "The is ${name} in not valid.",
              },
            ]}
          >
            <TextArea style={{
              rows:5,
              borderColor: '#d1d1d1',
              padding: '4px 11px',
              borderRadius: '6px',
            }}/>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="default" onClick={ handleCancel }>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={ loading }>
              Confirm
            </Button>
          </Form.Item>
        </Form>
    </Modal>
  );
}

export default CancelSubscriptionModal;