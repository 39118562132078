import React, {useState} from "react";
import Modal from "../Modal";
import {ReactComponent as LoaderIcon} from "../../assets/icons/loader.svg";
import securityApi from "../../securityApi";
import API_ROUTES from "../../enum/apiRoutes";
import './styles.scss';

const SignUpModal = ({ setToken, open, setOpen, setLoginOpen}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setOpen(false);
    }
    const openLoginModal = () => {
        setOpen(false);
        setLoginOpen(true);
    }

    const submitData = async () => {
        const form = document.getElementById('signup');
        const confirmEl = document.getElementById('secondPassword');
        const formData = new FormData(form);
        if (!validatePassword(formData)) {
            confirmEl.setCustomValidity('Password mismatch');
        } else {
            removeError();
        }

        if (form.checkValidity() === false) {
            return form.reportValidity();
        }

        setLoading(true);

        try {
            const response = await securityApi.post(API_ROUTES.register, formData);

            const email = response.data.response.user.email;
            const token = response.data.response.token;

            localStorage.setItem('email', email);
            localStorage.setItem('token', token);

            setToken(token);
            setOpen(false);
        } catch (e) {
            setLoading(false);
            switch (e.response.status) {
                case 400:
                    setError('Email already exist');
                    break;
                default:
                    setError('Internal server error. Try later');
            }
        }
    }

    const removeError = () => {
        const confirmEl = document.getElementById('secondPassword');
        confirmEl.setCustomValidity('');
    }

    const validatePassword = (formData) => {
        if (formData.get('plainPassword[first]') !== formData.get('plainPassword[second]')) {
            return false;
        }
        return true;
    }

    const footer = (
        <>
            <button onClick={closeModal}>Cancel</button>

            {
                !loading &&
                <button className={'active'} onClick={submitData}>
                    Sign Up
                </button>
            }
            {
                loading &&
                <button className={'active btn-loader'}>
                    <LoaderIcon />
                </button>
            }
        </>
    );
    const body = (
        <form id={'signup'}>
            <div className={`form-item ${error ? 'error' : ''}`}>
                <label htmlFor={'email'}>E-mail</label>
                <input id={'email'} name={'email'} required={true} type={'email'} placeholder={'E-mail'} onInput={() => setError('')}/>
            </div>
            <div className={'form-item'}>
                <label>Password</label>
                <input required={true} name={'plainPassword[first]'} type={'password'} placeholder={'Password'}/>
            </div>
            <div className={'form-item'}>
                <label>Repeat Password</label>
                <input id="secondPassword" onInput={removeError} required={true} name={'plainPassword[second]'} type={'password'} placeholder={'Repeat Password'}/>
                {
                    error &&
                    <span className={'form-item-error'}>{ error }</span>
                }
            </div>
            <div className={'form-subitem'}>
                Already have an account?
                <p onClick={openLoginModal}>Sign in now</p>
            </div>
        </form>
    );

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            header={'Sign Up'}
            footer={footer}
            body={body}
        />
    );
}

export default SignUpModal;