import React from "react";
import API from "../../axiosApi";
import {PayPalButtons} from "@paypal/react-paypal-js";
import {message} from "antd";
import API_ROUTES from "../../enum/apiRoutes";
import SecurityApi from "../../securityApi";
import securityAPI from "../../securityApi";

const EventPaymentButton = ({ updateElements, price, id, className, setOpenLoginModal, logout }) => {
    const [ messageApi, contextHolder ] = message.useMessage();
    const success = () => {
        updateElements();
        messageApi.open({
            type: 'success',
            content: 'Event paid successfully!',
        });
    }

    const error = (orderId) => {
        messageApi.open({
            type: 'error',
            content: 'Payment error, report the order ID to the support service: ' + orderId,
        });
    }

    return (
        <>
            { contextHolder }
            <PayPalButtons
                className={className}
                style={{
                    color: "blue",
                    layout: "horizontal",
                    label: "buynow"
                }}
                onClick={async (data, actions) => {
                    if (localStorage.getItem('token')) {
                        return await securityAPI.post(API_ROUTES.check, null,{
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`,
                                accept: 'application/json',
                            },
                        }).then(() => {
                            return actions.resolve();
                        }, () => {
                            logout();
                            setOpenLoginModal(true);
                            return actions.reject();
                        })
                    } else {
                        setOpenLoginModal(true);
                        return actions.reject();
                    }
                }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: price / 100,
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        const orderId = details.id;

                        API.post('transaction/order/create', {
                            orderId: orderId,
                            productId: id,
                        }).then(() => {
                            success();
                        }, () => {
                            error(orderId);
                        });
                    });
                }}
            />
        </>
    );
}

export default EventPaymentButton;