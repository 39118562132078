export const formatIntToPrice = ( price ) => {
  if (!price) {
    price = 0;
  }

  price = price / 100;

  return  price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}