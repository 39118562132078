import axios from 'axios';
import {clearUserAuth} from "./utils/security";

const securityApi = axios.create({
    baseURL: 'https://api.airy.tv/security/',
    //baseURL: 'http://127.0.0.1:8000/security/',
    headers: {
        accept: 'application/json',
    },
});

securityApi.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const {status} = error.response;

    if (401 === status) {
        clearUserAuth();
    }

    return Promise.reject(error);
});

export default securityApi;
