import React from "react";
import {PayPalButtons} from "@paypal/react-paypal-js";
import API from "../../axiosApi";
import {message} from "antd";
import API_ROUTES from "../../enum/apiRoutes";
import SecurityApi from "../../securityApi";
import securityAPI from "../../securityApi";

const ChannelPaymentButton = ({ updateElements, id, className, setOpenLoginModal, logout }) => {
    const [ messageApi, contextHolder ] = message.useMessage();
    let planId = 'P-XXXXXXXXXXXXXXX';

    const success = () => {
        updateElements();
        messageApi.open({
            type: 'success',
            content: 'Subscription to the channel has been successfully completed!',
        });
    }

    const error = (orderId) => {
        messageApi.open({
            type: 'error',
            content: 'Payment error, report the order ID to the support service: ' + orderId,
        });
    }

  return (
      <>
          { contextHolder }
          <PayPalButtons
              options={{
                  vault: true
              }}
              className={className}
              style={{
                  color: "white",
                  layout: "horizontal",
                  label: "checkout"
              }}
              onClick={async (data, actions) => {
                  if (localStorage.getItem('token')) {
                      return await securityAPI.post(API_ROUTES.check, null,{
                          headers: {
                              Authorization: `Bearer ${localStorage.getItem('token')}`,
                              accept: 'application/json',
                          },
                      }).then(() => {
                          return API.get('transaction/plan/' + id)
                              .then((response) => {
                                  planId = response.data.planId;

                                  return actions.resolve();
                              }, () => {
                                  return actions.reject();
                              })
                      }, () => {
                          logout();
                          setOpenLoginModal(true);
                          return actions.reject();
                      })
                  } else {
                      setOpenLoginModal(true);
                      return actions.reject();
                  }
              }}
              createSubscription={(data, actions) => {
                  return actions.subscription.create({
                      'plan_id': planId
                  });
              }}
              onApprove={(data) => {
                  const subscriptionID = data.subscriptionID;

                  API.post('transaction/subscription/create', {
                      subscriptionID: subscriptionID,
                      productId: id,
                  }).then(() => {
                      success();
                  }, () => {
                      error(subscriptionID);
                  });
              }}
          />
      </>
  );
}

export default ChannelPaymentButton;