import axios from 'axios';

const axiosApi = axios.create({
  baseURL: 'https://api.airy.tv/api/v2.1.7',
  // baseURL: 'http://127.0.0.1:8000/api/v2.1.7',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    accept: 'application/json',
  },
});

axiosApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default axiosApi;
