import React, {useEffect, useState} from "react";
import logo from './images/logo.png';
import mac from './images/mac.png';
import phone from './images/phone.png';
import {Button, Spin} from "antd";
import LoginModal from "./components/LoginModal";
import ChannelPaymentButton from "./components/PaymentButtons/ChannelPaymentButton";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import API from "./axiosApi";
import securityAPI from "./securityApi";
import EventPaymentButton from "./components/PaymentButtons/EventPaymentButton";
import {formatIntToPrice} from "./utils/price";
import CancelSubscriptionModal from "./components/CancelSubscriptionModal/CancelSubscriptionModal";
import {Transaction} from "./components/Transaction/Transaction";
import './styles.scss';
import SignUpModal from "./components/SignUpModal";
import {clearUserAuth} from "./utils/security";
import API_ROUTES from "./enum/apiRoutes";

const Landing = () => {
    const [ openLoginModal, setOpenLoginModal ] = useState( false );
    const [ openSignupModal, setOpenSignupModal ] = useState( false );
    const [ token, setToken ] = useState( localStorage.getItem('token') );
    const [channel, setChannel] = useState(null);
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [channels, setChannels] = useState([]);
    const [ openCancelSubscriptionModal, setOpenCancelSubscriptionModal ] = useState( false );
    const [ cancelProductId, setCancelProductId ] = useState( null );
    const [ cancelType, setCancelType ] = useState( null );
    const [ showTransactions, setShowTransactions ] = useState( false );

    const logout = () => {
        clearUserAuth();
        setToken( null );
        setShowTransactions(false);
    }

    const handleOpenLoginModal = () => {
        setOpenLoginModal(true);
    }

    const checkToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            await securityAPI.post(API_ROUTES.check, null,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            }).then(() => {

            }, () => {
                logout();
            });
        }
    }

    const listChannels = async () => {
        setChannels([]);
        const items = await getItems('channel');

        setChannels(items);
        setChannel(items?.[0]);
    }

    const listEvents = async () => {
        setEvents([]);
        const items = await getItems('event');

        setEvents(items);
        setEvent(items?.[0]);
    }

    const getItems = async (type) => {
        setLoading(true);
        try {
            const response = await API.get(`transaction/list/${type}`);

            return response.data.list;
        } catch (error) {
            console.log(error);

            return [];
        }
    }

    const updateElements = async () => {
        await listChannels();
        await listEvents();
        setLoading(false);
    }

    useEffect( () => {
        checkToken();
    }, []);

    useEffect(() => {
        updateElements();
    }, [token]);

    const handleChangeChannel = (event) => {
        const channelId = event.target.value;
        const selected = channels.find((channel) => channel.id === channelId);

        setChannel(selected);
    }

    const handleChangeEvent = (event) => {
        const eventId = event.target.value;
        const selected = events.find((event) => event.id === eventId);

        setEvent(selected);
    }

    const handleCancelSubscription = (id, type) => {
        setOpenCancelSubscriptionModal(true);
        setCancelProductId(id);
        setCancelType(type);
    }

    const toggleShowTransactions = () => {
        setShowTransactions(!showTransactions);
    }

    const button = token ?
        <div className={'airy-header-login'}>
            <p>{ localStorage.getItem('email') }</p>
            {
                !showTransactions &&
                <Button type="text" onClick={ toggleShowTransactions }>Transactions</Button>
            }
            {
                showTransactions &&
                <Button type="text" onClick={ toggleShowTransactions }>Channels & Events</Button>
            }
            <Button type="text" onClick={ logout }>Sign Out</Button>
        </div>
        :
        <div className={'airy-header-login'}>
            <Button type="text" onClick={handleOpenLoginModal}>Sign In</Button>
        </div>

    return (
        <div className={'airy'}>
            { openLoginModal && <LoginModal open={ openLoginModal } setOpen={ setOpenLoginModal } setToken={setToken} setSignUpOpen={ setOpenSignupModal }/>}
            { openSignupModal && <SignUpModal open={ openSignupModal } setOpen={ setOpenSignupModal } setToken={setToken} setLoginOpen={ setOpenLoginModal }/>}
            <CancelSubscriptionModal
                open={ openCancelSubscriptionModal }
                setOpen={ setOpenCancelSubscriptionModal }
                id={ cancelProductId }
                setId={ setCancelProductId }
                type={ cancelType }
                setType={ setCancelType }
            />
            <div className={'airy-container'}>
                <div className={'airy-header'}>
                    <img src={ logo } alt={'Airy TV'} />
                    { button }
                </div>
                { showTransactions &&
                    <Transaction logout={logout}/>
                }
                { !showTransactions &&
                    <PayPalScriptProvider options={{ "client-id": "AehfhRfbPcZ4QFbf3iWsyjkC89tx6Ivq4aaqt4DlhBcyO9Z5hI9Wof-7FlRHyVF8yOhnbFus4sFiZSbE", vault: true }}>
                        { loading &&
                            <div className={'loader'}>
                                <Spin size={'large'} />
                            </div>
                        }
                        {
                            !loading &&
                            <>
                            { channels.length > 0 &&
                                <div className={'airy-body-item'}>
                                    <div className={'airy-body-item-container'}>

                                        <div className={'airy-body-item-info'}>
                                            <h1>
                                                Subscribe to a paid channel
                                            </h1>
                                            <img src={mac} style={{width: "700px"}} alt={''}/>
                                            <h1>
                                                Enjoy watching TV without restrictions
                                            </h1>
                                            <p>
                                                Choose any channel from the list and pay in one click
                                            </p></div>
                                        <div className={'airy-body-item-payment'}>
                                            <p>
                                                <b>List of channels</b>
                                            </p>
                                            <select onChange={handleChangeChannel}>
                                                {channels.map((channel) => {
                                                    return <option key={channel.id} value={channel.id}>
                                                        {channel.title}
                                                    </option>;
                                                })}
                                            </select>
                                            <p>
                                                <b>Total Play Time: </b> {channel?.duration}
                                            </p>
                                            <p>
                                                <b>Price: </b> {formatIntToPrice(channel?.price)}
                                            </p>
                                            {channel && channel.paid &&
                                                <button
                                                    onClick={() => handleCancelSubscription(channel.id, 'channel')}>Unsubscribe</button>
                                            }
                                            <div>
                                                {channels.map((item) => {
                                                    let display = 'none';
                                                    const selected = channel.id === item.id;
                                                    if (selected && !item.paid) {
                                                        display = 'block';
                                                    }

                                                    return <div key={item.id} style={{display: display}}>
                                                        <ChannelPaymentButton
                                                            updateElements={updateElements}
                                                            id={item.id}
                                                            planId={'test'}
                                                            setOpenLoginModal={setOpenLoginModal}
                                                            className={'payment-button'}
                                                            logout={logout}
                                                        />
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                events.length > 0 &&
                                <div className={'airy-body-item reverse'}>
                                    <div className={'airy-body-item-container'}>
                                        <div className={'airy-body-item-payment'}>
                                            <p>
                                                <b>List of events</b>
                                            </p>
                                            <select onChange={handleChangeEvent}>
                                                {events.map((event) => {
                                                    return <option key={event.id} value={event.id}>
                                                        {event.title}
                                                    </option>;
                                                })}
                                            </select>
                                            <p>
                                                <b>Price: </b> {formatIntToPrice(event?.price)}
                                            </p>
                                            {event && event.paid &&
                                                <button disabled={true}>Paid</button>
                                            }
                                            <div>
                                                {events.map((item) => {
                                                    let display = 'none';
                                                    const selected = event.id === item.id;
                                                    if (selected && !item.paid) {
                                                        display = 'block';
                                                    }

                                                    return <div key={item.id} style={{display: display}}>
                                                        <EventPaymentButton
                                                            updateElements={updateElements}
                                                            id={item.id}
                                                            price={item.price}
                                                            setOpenLoginModal={setOpenLoginModal}
                                                            className={'payment-button'}
                                                            logout={logout}
                                                        />
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                        <div className={'airy-body-item-info'}>
                                            <h1>
                                                Don't miss important events, watch live TV
                                            </h1>
                                            <img src={phone} style={{width: '250px'}} alt={''}/>
                                            <p>
                                                Choose events that are suitable and interesting to you and watch them on
                                                any
                                                device
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }
                            </>
                        }
                    </PayPalScriptProvider>
                }
            </div>
        </div>
    );
}

export default Landing;