import React from "react";
import {Tabs} from "antd";
import {SubscriptionTable} from "./SubscriptionTable";
import {TransactionTable} from "./TransactionTable";

export const Transaction = ({logout}) => {
    const tabs = [
        {
            key: '1',
            label: 'Transactions',
            children: <TransactionTable logout={logout} />,
        },
        {
            key: '2',
            label: 'Subscriptions',
            children: <SubscriptionTable logout={logout} />,
        }
    ];

    return (
        <div className={'airy-transaction-tab'}>
            <Tabs defaultActiveKey="1" items={tabs}/>
        </div>
    )
}