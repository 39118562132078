import React, {useState} from "react";
import Modal from "../Modal";
import securityApi from "../../securityApi";
import API_ROUTES from "../../enum/apiRoutes";
import {ReactComponent as LoaderIcon} from "../../assets/icons/loader.svg";

import './styles.scss';

const LoginModal = ({ open, setToken, setOpen, setSignUpOpen }) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const openSignUpModal = () => {
        setOpen(false);
        setSignUpOpen(true);
    }
    const closeModal = () => setOpen(false);

    const submitData = async () => {
        const form = document.getElementById('login');
        if (!form.checkValidity()) {
            return form.reportValidity();
        }

        setLoading(true);

        const formData = new FormData(form);
        try {
            const response = await securityApi.post(API_ROUTES.login, formData);

            const email = response.data.response.user.email;
            const token = response.data.response.token;

            localStorage.setItem('email', email);
            localStorage.setItem('token', token);

            setToken(token);
            setOpen(false);
        } catch (e) {
            setLoading(false);
            switch (e.response.status) {
                case 400:
                    setError('Email or password is invalid');
                    break;
                default:
                    setError('Internal server error. Try later');
            }
        }
    }
    
    const footer = (
      <>
          <button onClick={closeModal}>Cancel</button>
          {
              !loading &&
              <button className={'active'} onClick={submitData}>
                  Sign In
              </button>
          }
          {
              loading &&
              <button className={'active btn-loader'}>
                <LoaderIcon />
              </button>
          }
      </>
    );
    const body = (
        <form id={'login'}>
            <div className={`form-item ${error ? 'error' : ''}`}>
                <label htmlFor={'email'}>E-mail</label>
                <input id={'email'} name={'email'} required={true} type={'email'} placeholder={'E-mail'} onInput={() => setError('')} />
            </div>
            <div className={`form-item ${error ? 'error' : ''}`}>
                <label>Password</label>
                <input required={true} name={'password'} type={'password'} placeholder={'Password'} onInput={() => setError('')} />
                {
                    error &&
                    <span className={'form-item-error'}>{ error }</span>
                }
            </div>
            <div className={'form-subitem'}>
                New to Airy?
                <p onClick={ openSignUpModal }>
                    Sign up now
                </p>
            </div>
        </form>
    );

    return (
        <Modal
            header={'Sign In'}
            footer={footer}
            body={body}
            open={ open }
            setOpen={ setOpen }
      />
  )
}

export default LoginModal;
