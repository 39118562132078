import {useEffect, useState} from "react";
import {Table} from "antd";
import API from "../../axiosApi";
import {formatIntToPrice} from "../../utils/price";

export const TransactionTable = ({logout}) => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);

    const getTransactions = async (currentPage, perPage) => {
        try {
            const response = await API.get(`transaction/list?page=${currentPage}&limit=${perPage}`);

            return response.data;
        } catch (error) {
            const {status} = error.response;

            if (401 === status) {
                logout();
            }

            return [];
        }
    }

    const loadTransactions = async (currentPage, perPage) => {
        const {transactions, total, page, limit}  = await getTransactions(currentPage, perPage);

        setTransactions(transactions);
        setTotal(total);
        setCurrentPage(page);
        setPerPage(limit);
    }

    useEffect(() => {
        loadTransactions(currentPage, perPage);
    }, [currentPage, perPage]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: '180px',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: '80px',
            render: (text) => {
                return formatIntToPrice(text)
            }
        },
        {
            title: 'Order ID',
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'center',
            width: '120px',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            width: '80px',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            width: '180px',
        },
    ];

    const handleChangePagination = (page) => {
        setCurrentPage(page);
    };

    const handleChangePageSize = (_current, size) => {
        setPerPage(size);
    };

    return (
        <div className="transaction-table">
            <Table
                dataSource={transactions}
                columns={columns}
                bordered
                pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: perPage,
                    total: total,
                    onChange: handleChangePagination,
                    onShowSizeChange: handleChangePageSize,
                    pageSizeOptions: [
                        10,
                        20,
                        50,
                    ]
                }}
            />
        </div>
    )
}