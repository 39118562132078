import {Popconfirm, Table } from "antd";
import React, {useEffect, useState} from "react";
import API from "../../axiosApi";
import {CloseOutlined} from "@ant-design/icons";
import CancelSubscriptionModal from "../CancelSubscriptionModal/CancelSubscriptionModal";
import {formatIntToPrice} from "../../utils/price";

export const SubscriptionTable = ({logout}) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);

    const [ openCancelSubscriptionModal, setOpenCancelSubscriptionModal ] = useState( false );
    const [ cancelProductId, setCancelProductId ] = useState( null );
    const [ cancelType, setCancelType ] = useState( null );

    const getSubscriptions = async (currentPage, perPage) => {
        try {
            const response = await API.get(`transaction/subscriptions/list?page=${currentPage}&limit=${perPage}`);

            return response.data;
        } catch (error) {
            const {status} = error.response;

            if (401 === status) {
                logout();
            }

            return [];
        }
    }

    const loadSubscriptions = async (currentPage, perPage) => {
        const {subscriptions, total, page, limit} = await getSubscriptions(currentPage, perPage);

        setSubscriptions(subscriptions);
        setTotal(total);
        setCurrentPage(page);
        setPerPage(limit);
    }

    useEffect(() => {
        loadSubscriptions(currentPage, perPage);
    }, [currentPage, perPage]);

    const confirmToCancel =  (item) => async () => {
        setOpenCancelSubscriptionModal(true);
        setCancelProductId(item.productId);
        setCancelType('channel');
    }

    const handleChangePagination = (page) => {
        setCurrentPage(page);
    };

    const handleChangePageSize = (_current, size) => {
        setPerPage(size);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: '180px',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: '80px',
            render: (text) => {
                return formatIntToPrice(text)
            }
        },
        {
            title: 'Expired date',
            dataIndex: 'expiredAt',
            key: 'expiredAt',
            align: 'center',
            width: '180px',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            width: '180px',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            align: 'center',
            width: '80px',
            render: (id, item) => (
                <Popconfirm
                    title="Cancel the Subscription"
                    description="Are you sure to cancel this Subscription?"
                    onConfirm={confirmToCancel(item)}
                    okText="Yes"
                    cancelText="No"
                >
                    <CloseOutlined
                        style={{ color: 'red' }}
                    />
                </Popconfirm>
            ),
        },
    ];
    return (
        <div className="subscription-table">
            { openCancelSubscriptionModal &&
                <CancelSubscriptionModal
                    open={ openCancelSubscriptionModal }
                    setOpen={ setOpenCancelSubscriptionModal }
                    id={ cancelProductId }
                    setId={ setCancelProductId }
                    type={ cancelType }
                    setType={ setCancelType }
                />
            }
            <Table
                dataSource={subscriptions}
                columns={columns}
                bordered
                pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: perPage,
                    total: total,
                    onChange: handleChangePagination,
                    onShowSizeChange: handleChangePageSize,
                    pageSizeOptions: [
                        10,
                        20,
                        50,
                    ]
                }}
            />
        </div>
    )
}