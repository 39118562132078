import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import './styles.scss';

const Modal = ({ open, setOpen, header, footer, body }) => {

    return (
        <>
            {
                open &&
                <div className={'modal'}>
                    <div className={'modal-window'}>
                        <div className={'modal-window-close'} onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </div>
                        <div className={'modal-window-header'}>
                            { header }
                        </div>
                        <div className={'modal-window-body'}>
                            { body }
                        </div>
                        <div className={'modal-window-footer'}>
                            { footer }
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default Modal;